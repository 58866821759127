/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-10-13 15:21:27
 */
import request from '@/util/request'

//根据id查询全球网络分布详情
export function getRegionDetail(params) {
  return request({
    url: '/home/api/ho-distribute/get-region-detail',
    method: 'get',
    params
  })
}

//全球网络分布情况
export function getDistribute(params) {
  return request({
    url: '/home/api/ho-distribute/get-region-distribute',
    method: 'get',
    params
  })
}
// banner
export function getHoLeader(data) {
  return request({
    url: '/home/api/ho-leader/getHoLeader',
    method: 'post',
    data
  })
}

//商品展示-家族系列列表
export function getHoProductRange(data) {
  return request({
    url: 'home/api/ho-leader/getHoProductRange',
    method: 'post',
    data
  })
}

//商品展示商品简介详情
export function getHoProductDetails(data) {
  return request({
    url: 'home/api/ho-product-details/getHoProductDetails',
    method: 'post',
    data
  })
}

//MY_WAL_22轮播
export function getIndexBanner(params) {
  return request({
    url: '/home/api/bd-advert-banner/list',
    method: 'get',
    params
  })
}

//推荐平均用时
export function getMemberRecommend(params) {
  return request({
    url: '/member/api/census/get-member-recommend-time',
    method: 'get',
    params
  })
}

//安置图
export function getPlaceMent(params) {
  return request({
    url: '/member/api/achieve/query-place-tree',
    method: 'get',
    params
  })
}

//获取等级
export function getGradeIcon(params) {
  return request({
    url: '/member/api/member-structure/get-avatar-info',
    method: 'get',
    params
  })
}
//安置图详情
export function getTreeDetail(data) {
  return request({
    url: '/member/api/achieve/query-place-tree-detail',
    method: 'post',
    data
  })
}
//获取新增业绩
export function getIndexAddAchieve(params) {
  return request({
    url: '/member/api/member/new-add-achieve',
    method: 'get',
    params
  })
}
//获取日期下拉
export function getDayType(params) {
  return request({
    url: '/system/pub/enums/get-day-type',
    method: 'get',
    params
  })
}
//获取荣誉奖衔
export function getIndexAwards(params) {
  return request({
    url: '/member/api/member/index-awards',
    method: 'get',
    params
  })
}
//获取真实业绩
export function getPerformance(params) {
  return request({
    url: '/member/api/achieve/query-real-performance',
    method: 'get',
    params
  })
}
//获取安置业绩分布
export function azAchievement(params) {
  return request({
    url: '/report/api/achieve/az-achievement',
    method: 'get',
    params
  })
}
//获取真实业绩分布
export function realPerformancePic(params) {
  return request({
    url: '/member/api/achieve/query-real-performance-pic',
    method: 'get',
    params
  })
}

//首页-我的市场
export function getMyMarket(params) {
  return request({
    url: '/sale/api/order/index-myMarket',
    method: 'get',
    params
  })
}
// 推荐小区分布
export function getRecommendCommunity(params) {
  return request({
    url: '/report/api/achieve/recommend-community',
    method: 'get',
    params
  })
}
// 获取用户是否显示弹窗
export function indexPopScreen(params) {
  return request({
    url: '/system/api/notice/index-pop-screen',
    method: 'get',
    params
  })
}
// 内信弹窗
export function getIndexPopmail(params) {
  return request({
    url: '/system/api/notice/index-pop-mail',
    method: 'get',
    params
  })
}

//直推排行弹窗
export function getMemberDirectpush(params) {
  return request({
    url: '/member/api/member/get-member-direct-push',
    method: 'get',
    params
  })
}




// 获取海外用户是否需要弹出用户协议
export function getAlertUserAgreement(params){
  return request({
    url: '/system/api/agreement/get-alert-user-agreement',
    method: 'get',
    params
  })
}

// 用户点击确认统一协议
export function getAlertUserAgreementPost(data){
  return request({
    url: '/system/api/agreement/use-user-agreement',
    method: 'post',
    data
  })
}

// 获取抗衰达人
export function repurchase(data){
  return request({
    url: '/member/api/achieve/repurchase',
    method: 'post',
    data
  })
}

// 获取推荐达人
export function recommend(data){
  return request({
    url: '/member/api/achieve/recommend',
    method: 'post',
    data
  })
}

//全球分布市场
export function getGloableCompany(params){
  return request({
    url: '/home/api/ho-global-company/list',
    method: 'get',
    params
  })
}
//植树活动
export function queryTreeActivity(params){
  return request({
    url: '/activity/api/sa-tree-order/queryTreeActivity',
    method: 'get',
    params
  })
}
//首购分布
export function firstPv(params){
  return request({
    url: '/report/api/achieve/query-first-performance-pic',
    method: 'get',
    params
  })
}
//复购分布
export function secondPv(params){
  return request({
    url: '/report/api/achieve/query-repurchase-performance-pic',
    method: 'get',
    params
  })
}
//校验服务有效期
export function agreement_expire(params){
  return request({
    url: '/member/api/member/agreement_expire',
    method: 'get',
    params
  })
}
//年度奖衔
export function yearAwards(params){
  return request({
    url: '/member/api/member/year-awards-list',
    method: 'get',
    params
  })
}
//基本信息弹框
export function index_repurchase(params){
  return request({
    url: '/member/api/member/index_repurchase',
    method: 'get',
    params
  })
}


